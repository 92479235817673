body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}
.board-row {
  width:100%;
  margin:0;
  padding:0;
  display:flex;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1.5px solid rgb(207, 207, 207);
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.square:hover {
  background: rgb(255, 229, 252)
}

.square-border-top {
  border-top: 2px solid black;
}
.square-border-bottom {
  border-bottom: 2px solid black;
}
.square-border-left {
  border-left: 2px solid black;
}
.square-border-right {
  border-right: 2px solid black;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}
.game-board {
  margin: 20px;
}

.game-info {
  margin-bottom: 20px;
}

/* Icons */
.circle {
  width: 8px;
  height: 8px;
  opacity: 0.4;
}
.star {
  width: 25px;
  height: 25px;
}

.is-solved{
  background: rgb(178, 255, 178);
  pointer-events: none;
}

/* React bootstrap navbar */
.navbar {
  padding: 10px;
  background: rgb(116, 130, 255);
}
.navbar-brand {
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: 600;
}
.logo-home {
  margin-right: 5px;
}

.btn {
  font-size: 1.2rem;
}
.btn-custom {
  background-color: rgb(145, 156, 255);
  color: white;
  font-size: 1.2rem;
}
.btn-custom:hover {
  background-color: rgb(81, 98, 249);
  color: white;
}

.play {
  margin-left: 10px;
}

.next-puzzle {
  color: white;
  text-decoration: none;
}

.username {
  color: white;
  margin-right: 10px;
}